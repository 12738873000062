<template>
  <div>
    <div class="w1200">
      <ul class="list_box">
        <li class="list_item" v-for="item in list" @click="goDetails(item)" :key='item.id'>{{item.service_name}}</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      list:[]
    }
  },
  mounted() {
    this.getList()
  },
  methods:{
    getList() {
      this.$api.getAllServiceArea().then(res=>{
        this.list = res.data
      })
    },
    goDetails(item) {
      this.$router.push({
        path:'/serviceDetails/'+item.id,
        query:{
          type:this.$route.query.type
        }
      })
    }
  }
}
</script>

<style lang='stylus' scoped>
@import '~@/assets/stylus/variable'
  .list_box
    display: flex
    flex-wrap: wrap
    .list_item
      width: 373px
      flex-shrink: 0
      height: 64px
      line-height: 64px
      border 1px solid #eee
      background: #fff
      border-left 4px solid $theme-color
      padding-left 20px
      box-sizing border-box
      margin 0 40px 20px 0
      cursor pointer
      font-size 16px
      &:nth-child(3n)
        margin-right: 0
      &:hover
        background: linear-gradient(to right,#72cff4 0%,#1567e2 100%)
        border-left none
        color #fff
</style>